import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import $ from 'jquery';
import moment from 'moment';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import form from 'jquery-form';
export default {
  data: function data() {
    var _this = this;

    return {
      columns: [{
        title: '设备别名',
        dataIndex: 'equipmentUUID',
        width: 100,
        // sortOrder: true,
        scopedSlots: {
          customRender: 'equipmentUUID'
        },
        customRender: function customRender(equipmentUUID) {
          var name = '';

          _this.equipmentList.map(function (equip) {
            if (equip.numbering === equipmentUUID) {
              if (equip.typeId == 3) {
                name = equip.parentEquipmentName + '-' + equip.name;
              } else {
                name = equip.name;
              }
            }
          });

          return name;
        }
      }, {
        title: '充放电类型',
        dataIndex: 'chargeAndDischargeState',
        width: 60,
        scopedSlots: {
          customRender: 'chargeAndDischargeState'
        } // customRender: (s) => {
        // 	return s == 0 ? '充电' : s == 1 ? '放电' : '未知';
        // }

      }, {
        title: '平均功率（kWh）',
        dataIndex: 'averagePower',
        width: 90,
        scopedSlots: {
          customRender: 'averagePower'
        },
        customRender: function customRender(averagePower, row) {
          return (row.chargeAndDischargeElectricEnergy / ((new Date(row.endTime).getTime() - new Date(row.startTime).getTime()) / 3600000)).toFixed(2);
        }
      }, {
        title: '累计电能（kWh）',
        dataIndex: 'chargeAndDischargeElectricEnergy',
        width: 90,
        scopedSlots: {
          customRender: 'chargeAndDischargeElectricEnergy'
        }
      }, {
        title: '起始时间',
        dataIndex: 'startTime',
        width: 100,
        scopedSlots: {
          customRender: 'startTime'
        }
      }, {
        title: '起始电表读数（kWh）',
        dataIndex: 'initialMeterReading',
        width: 110,
        scopedSlots: {
          customRender: 'initialMeterReading'
        }
      }, {
        title: '结束时间',
        dataIndex: 'endTime',
        width: 100,
        scopedSlots: {
          customRender: '结束时间'
        }
      }, {
        title: '结束电表读数（kWh）',
        dataIndex: 'endMeterReading',
        width: 110,
        scopedSlots: {
          customRender: 'endMeterReading'
        }
      }, {
        title: '充放电时长(h)',
        dataIndex: 'duration',
        width: 70,
        scopedSlots: {
          customRender: 'duration'
        },
        customRender: function customRender(duration, row) {
          if (row.endTime == null) {
            return ((new Date().getTime() - new Date(row.startTime).getTime()) / 3600000).toFixed(2);
          } else {
            return ((new Date(row.endTime).getTime() - new Date(row.startTime).getTime()) / 3600000).toFixed(2);
          }
        }
      }],
      moment: moment,
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      scroll: {
        y: 660
      },
      datePickerLocale: datePickerLocale,
      remark: "",
      keyboard: false,
      confirmRemark: null,
      FormVisible: false,
      equipTypeList: [],
      equipmentList: [],
      dateFormat: 'YYYY-MM-DD',
      search: {
        stationId: localStorage.getItem('stationId'),
        startTime: moment(),
        endTime: moment(),
        equipmentId: '-1',
        length: 1000000000,
        page: 1,
        startIndex: 0
      },
      selectAlarm: null,
      tableData3: [],
      total: 0,
      showTotal: true,
      confirmShow: false,
      loading: false
    };
  },
  mounted: function mounted() {
    var this_ = this;
    this_.getEquipmentList();
    this_.getList();
  },
  methods: {
    onChange: function onChange() {
      if (typeof this.search.startTime === 'string') {
        this.search.startTime = this.search.startTime.split(" ")[0];
        this.search.startTime = moment(this.search.startTime);
      }

      if (typeof this.search.endTime === 'string') {
        this.search.endTime = this.search.endTime.split(" ")[0];
        this.search.endTime = moment(this.search.endTime);
      }
    },
    select: function select(value) {
      var this_ = this;
      this_.getEquipmentList();
    },
    getEquipmentList: function getEquipmentList() {
      var this_ = this;
      var data = {
        stationId: localStorage.getItem('stationId')
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/search_condition',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentList = [];
          d.data.data.map(function (item) {
            if (item.typeId < 4) {
              this_.equipmentList.push(item);
            }
          });
          this_.equipmentList.push({
            id: localStorage.getItem('stationId'),
            numbering: 'V' + localStorage.getItem('stationId')
          });
          this_.equipmentList.sort(this_.sortNum);
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    sortNum: function sortNum(a, b) {
      if (a.typeId == 3 && b.typeId == 3) {
        return (a.num - b.num) * 1;
      } else if (a.typeId == 3) {
        return -1;
      } else {
        return a.typeId - b.typeId;
      }
    },
    //获取刷新列表数据
    getList: function getList() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId,
        equipmentId: this.search.equipmentId,
        length: this.search.length,
        page: this.search.page,
        startIndex: this.search.startIndex
      };

      if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      }

      var dataTime = '';

      if (this_.search.startTime != null) {
        dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
        data.startTime = dataTime;
      } else {
        data.startTime = '1970-01-01 00:00:00';
      }

      if (this_.search.endTime != null) {
        dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
        data.endTime = dataTime;
      } else {
        if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {
          this_.tableData3 = [];
          return;
        } else {
          data.endTime = moment().format('YYYY-MM-DD hh:mm:ss');
        }
      }

      data.startIndex = (data.page - 1) * data.length;
      this_.loading = true;
      axios({
        method: 'post',
        url: '/emind/history/data/charge_discharge_record',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.total = d.data.recordsTotal;
        this_.tableData3 = d.data.data;

        for (var i = 0; i < this_.tableData3.length; i++) {
          this_.tableData3[i].key = i;
        }

        this_.loading = false;
      }).catch(function (error) {
        this_.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.getList();
    },
    exportExcel: function exportExcel(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var name = arguments.length > 2 ? arguments[2] : undefined;
      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: url,
          // 请求地址
          data: options,
          // 参数
          responseType: 'blob' // 表明返回服务器返回的数据类型

        }).then(function (response) {
          resolve(response.data);
          var blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          var fileName = name;

          if (response.headers['content-disposition'].endsWith('.xls"')) {
            fileName = fileName + '.xls';
          } else if (response.headers['content-disposition'].endsWith('.zip"')) {
            fileName = fileName + '.zip';
          }

          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); //释放内存

            window.URL.revokeObjectURL(link.href);
          }
        }, function (err) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      });
    },
    exportFn: function exportFn() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId,
        equipmentId: this.search.equipmentId,
        length: this.search.length,
        page: this.search.page,
        startIndex: this.search.startIndex
      };

      if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
        this.$message.error('结束时间早于起始时间，请重新设置查询时间！');
        return;
      }

      var dataTime = '';

      if (this_.search.startTime != null) {
        dataTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
        data.startTime = dataTime;
      } else {
        data.startTime = '1970-01-01 00:00:00';
      }

      if (this_.search.endTime != null) {
        dataTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
        data.endTime = dataTime;
      } else {
        if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {
          this_.tableData3 = [];
          return;
        } else {
          data.endTime = moment().format('YYYY-MM-DD hh:mm:ss');
        }
      }

      var now = new Date();
      var url = '/emind/history/data/charge_discharge_record/export/' + now.getTime();
      this_.exportExcel(url, data, '充放电记录-' + now.Format("yyyy-MM-dd_hh-mm-ss"));
    }
  }
};